
            @import 'src/styles/mixins.scss';
        
.wrapper {
    background-image: url("/assets/images/bg-pattern.jpg");
    background-size: cover;
    background-color: #f5f4ef;
    position: relative;
    min-height: calc(100vh - 62px);
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        top: 0;
        position: absolute;
        background-color: #f5f4ef;
        z-index: 1;
    }
    &::after {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        top: 0;
        position: absolute;
        background-image: url("/assets/images/bg-pattern.jpg");
        background-size: 100%;
        background-position: right bottom;
        background-repeat: no-repeat;
        z-index: 2;
    }
}
