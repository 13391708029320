
            @import 'src/styles/mixins.scss';
        
.animation {
    transition: all 0.3s ease-in-out;
    color: #fff;
}
.animation:hover {
    transform: translateY(-7px);
    transition: all 0.3s ease-in-out;
}
