
            @import 'src/styles/mixins.scss';
        
.animation {
    transition: all 0.3s ease-in-out;
}
.animation:hover {
    transform: translateY(-7px);
    transition: all 0.3s ease-in-out;
}
.mainContainer {
    position: relative;
    margin-top: 70px;
    &::before {
        background-color: white;
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        z-index: -2;
    }

    .cardsContainer {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        text-align: center;

        .cardContainer {
            flex: 2 50px;
            box-shadow: 0px 4px 10px 0px #00000026;

            @media screen and (max-width: 1000px) {
                flex: 1 33%;
            }

            @media screen and (max-width: 500px) {
                flex: 1 50%;
            }
        }
    }
}
